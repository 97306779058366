ul.thumbnails.image_picker_selector {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  ul {
    overflow: auto;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0px;
    margin: 0px; }
  li {
    &.group_title {
      float: none; }
    margin: 0px 12px 12px 0px;
    float: left;
    .thumbnail {
      padding: 6px;
      border: 1px solid #dddddd;
      max-width: 100px;
      &.selected {
        background: #0088cc; } } } }
