.navbar {
  min-height: 54px;

  .navbar-logo {
    margin-right: 25px;
    img {
      height: 40px;
      margin-top: -3px;
      margin-bottom: -10px; } }

  .navbar-nav {
    padding-top: 7px; }

  &.navbar-fixed-top {
    position: fixed;
    top: 26px;
    z-index: 1010; } }

.navbar-inverse {
  background: #202329; }

.navbar {
  .navbar-nav > {}
  li > a {
    color: #DADADA;
    transition: all 240ms ease;
    &:hover {
      color: #fff; }
    .active > a {
      color: #fff;
      background-color: #161719;
      &:hover, &:focus {
        color: #fff;
        background-color: #161719; } }
    .open > a {
      color: #fff;
      &:hover, &:focus {
        color: #fff; } } }
  .open .login_menu {
    padding: 12px;
    width: 250px; }
  a:hover,
  a:focus {
    text-decoration: none; } }

@include media-breakpoint-down(sm) {
  .navbar-nav .nav-item + .nav-item {
    margin-left: 0.75rem; }
  .navbar-nav.pull-right {
    clear: both; }
  #collapsenav {
    clear: both; } }
