.md {
  word-wrap: break-word;
}

.md code {
  white-space: normal;
}

.md .show_spoiler {
  background: #F8F8F8;
  padding: 3px;
  cursor: pointer;
}

.md .spoiler {
  display: none;
}

.md a.image:before {
  margin-right: 3px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f03e";
}

.md a.yt-video:before {
  margin-right: 3px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f03d";
}

.md img {
  vertical-align: text-top;
  max-width: 100%;
  display: block;
}

.md iframe {
  display: block;
}

.md blockquote p {
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
}
