body {
	font: normal normal normal 1em/1.5 "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.navbar-inverse .navbar-toggler {
	color: #dadada;
}

.sidebar .btn-group .half-width {
	min-width: 8rem;
}

.navbar-inverse {
	background: #9c386b;
}

.navbar-logo {
	color: #fff;
	font-family: Arvo, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-size: 2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: opacity 240ms ease;
	opacity: 1.0;
	letter-spacing: -1px;
}

	.navbar-logo:hover {
		color: #fff;
		text-decoration: none;
		opacity: 0.7;
	}

.nav-search {
	max-width: 18em;
	margin-top: -0.25em;
}

.topic-list {
	margin-top: 1em;

	li {
		margin-top: 1em;
		line-height: 1.2;

		&:first-child {
			margin-top: 0;
		}

		strong {
			font-size: 1.125em;
		}
	}
}
