.content_sort .selected, .content_filter .selected {
  font-weight: bold; }

.content {
  position: relative;
  padding-bottom: 12px;
  overflow: visible;
  float: left;
  width: 100%;
  .voting {
    float: left;
    width: 70px;
    min-height: 1px; }
  .img-thumbnail {
    width: 100px;
    margin-right: 10px;
    padding: 0;
    border: none;
    border-radius: 4px;
    transition: none;
    &.refreshing {
      font-size: 50px;
      padding: 8px 0;
      text-align: center; } }
  .btn-default {
    color: #fff;
    background-color: #00a3b4;
    border-color: #137883;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .btn-success {
    color: #FFF;
    background-color: #5CB85C;
    border-color: #4CAE4C;
    span.fa.fa-caret-up:before {
      content: '\f00c'; } }
  .btn-danger {
    color: #FFF;
    background-color: #D9534F;
    border-color: #D43F3A; }
  .vote-btn-up {
    margin-bottom: 5px; }
  .content_head {
    a {
      color: #00a3b4;
      letter-spacing: -0.5px;
      font-weight: 600;
      font-size: 16px;
      margin-right: 0.5em; }
    .content_domain {
      font-size: 0.75em;
      color: #666; } }
  .content_desc {
    font-size: 14px;
    small {
      font-size: 12px; } }
  .content_actions {
    display: none;
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 13px;
    a {
      margin-left: 8px; } }
  &:hover {
    .content_actions {
      display: block; } }
  .summary {
    margin-top: 3px;
    a {
      margin-right: 5px; }
    i.fa {
      color: lightgray;
      margin-right: 3px;
      margin-left: 10px;
      &:first-of-type {
        margin-left: 0px; } }
    .when-and-who {
      display: inline-block;
      margin-right: 1em; }
    .content_user {
      color: #222; }
    .content-links {
      a {
        display: inline-block;
        margin-left: 0.5em;
        color: #0275d8;
        &:first-child {
          margin-left: 0; } }
      i {
        display: inline-block;
        margin-left: 0.5em !important; } } }
  .nsfw {
    color: #E93939;
    font-size: 10px;
    vertical-align: super; }
  .eng {
    color: #8F8F8F;
    font-size: 10px;
    vertical-align: super; } }

.vote-up {
  color: #fff !important; }

.vote-down {
  color: #DB5E5E !important; }

.content .vote-btn-up, .content .vote-btn-down {
  width: 50px;
  font-size: 16px;
  padding: 0 0 0.75; }

.content h2, .content h1 {
  font-size: 18px; }

.content_preview_link {
  cursor: pointer;
  background: #f8f8f8;
  padding: 2px 5px;
  margin-right: 10px; }

.content_preview {
  margin: 10px 0 25px 70px;
  clear: both; }

.content_preview img, .content_preview video {
  max-width: 100%; }

.related_link {
  width: 370px;
  display: inline-block;
  vertical-align: top;
  .voting {
    float: left;
    width: 50px; }
  .vote-btn-up {
    margin-bottom: 5px; }
  .glyphicon-trash {
    font-size: 12px;
    color: #7E7E7E;
    cursor: pointer;
    margin-left: 5px;
    background: #E7E7E7;
    padding: 2px 3px 3px 2px;
    border-radius: 2px;
    border: 1px solid #D8D8D8; }
  .nsfw {
    color: #E93939;
    font-size: 10px;
    vertical-align: super; }
  .info {
    font-size: 12px; } }

