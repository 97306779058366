@media (max-width: 768px) {

  /* notifications dropdown */
  .navbar-collapse {
    width: 300px;
    max-height: 360px;
    position: absolute;
    right: 15px;
    background: #242424;
  }

  .navbar-nav.navbar-right {
    background: #3C3C3C;
    border-top: 1px solid #686868;
  }

  .navbar-nav.navbar-right > li > a {
    background: #333;
  }

  .navbar-inverse .navbar-nav.navbar-right > .open > a {
    background-color: #3C3C3C;
  }

  .navbar-nav.navbar-right > li {
    float: left;
    width: 50%;
  }

  .navbar-nav.navbar-right > .notifications_dropdown {
    width: 33%;
  }

  .navbar-nav.navbar-right > .user_dropdown {
    width: 67%;
  }

  .navbar-nav .open .notifications {
    width: 298px;
  }

  .navbar-nav .open .user_menu {
    width: 298px;
    margin-left: -49%;
  }

  .navbar-nav .open .login_menu {
    width: 200%;
    padding: 0 15px;
  }

  .navbar-form .checkbox, .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar-form .checkbox label, .radio label {
    color: #D6D6D6;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
    color: #DEE9F1;
  }

  .notifications_list a {
    width: 100%;
  }

  .user_menu .glyphicon {
    color: #BDBDBD;
  }

  .user_menu .divider {
    background-color: #606C79;
  }

  .comment .comment_actions, .entry .entry_actions {
    visibility: visible;
  }
}

@media (max-width: 400px) {

  .main_col {
    form {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  .navbar-toggleable-sm .navbar-nav .nav-item {
    float: left;
    margin-right: 15px;
  }
  .navbar-toggleable-sm .navbar-nav .nav-search.nav-item {
    margin: 25px 0 0 0;
  }

  /* contents */

  .content .voting {
    width: 60px;
  }

  .content .img-thumbnail {
    width: 50px;
    margin-right: 0;
  }

  /* entries */

  .entry .entry_avatar img {
    height: 30px;
    width: 30px;
  }

  .entry_header {
    margin-left: 36px;
  }

  .entry_header .fa-clock-o, .entry_header time {
    display: none;
  }

  .entry_header .pull-right {
    font-size: 90%;
  }

  .entry_header .pull-right > .glyphicon {
    display: none;
  }

  .entry_text {
    margin-left: 36px;
  }

  .entry_reply {
    margin-left: 15px;
  }
}

