body > .container {
  background: #FFF;
  padding-top: 20px;
}

@media (min-width: 992px) {
  .main_col {
    width: 72%;
  }

  .sidebar {
    width: 28%;
  }
}

.sidebar {
  font-size: 12px;
}

.sidebar .well {
  padding: 15px;
}

.navbar-fixed-margin {
  padding-top: 109px;
}

.btn-default {
  border-color: #E4E4E4;
}

.popover {
  z-index: 1999;
}

.spinner {
  -webkit-transform-origin: 50% 58%;
  transform-origin:50% 58%;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

.input-group-btn {
  vertical-align: top !important;
}

/* TABLES */
thead .glyphicon {
  color: rgba(0, 0, 0, 0.33);
  margin-right: 5px;
}

/* WIDGETS */

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f8f8f8;
  border: 1px solid #FFF;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.03);
}

.action_link {
  cursor: pointer;
}

.conversation_messages {
  max-height: 600px;
  overflow: auto;
}

.conversations_list {
  font-size: 13px;
}

.add_content_widget a {
	font-size: 18px;
	display: inline-block;
	margin-bottom: 0.25em;
}

.conversations_list img {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 10px;
}

.subscribed_dropdown, .moderated_dropdown, .observed_dropdown, .folder_dropdown {
  position: static;
}

.btn-secondary i.fa {
  color: #ccc;
  margin-right: 2px;
}

.user_dropdown img {
  width: 20px;
  margin-right: 4px;
  border-radius: 20px;
}

.user_menu i.fa {
  margin-right: 7px;
  color: #ababab;
}

/* USER TYPES */
.user_admin {
  color: #c12e2a;
}

.user_deleted {
  color: #5A5A5A;
  text-decoration: line-through;
}

/* VOTING */
.voting .count {
  font-weight: bold;
}

.voting .popover {
  font-size: 11px;
}

.voting .popover .time {
  color: #929292;
}

.voting .popover-content {
  max-height: 220px;
  overflow-y: auto;
}

/* GROUPS */
.group_name {
  margin: 5px 0 0 0;
}

.group_buttons {
  margin-top: 15px;
}

.group_desc {
  margin-top: 20px;
}

.group_stats_widget p {
  margin: 0;
}

.add_content_widget p {
  margin: 0;
  font-size: 13px;
}

/* POPULAR CONTENTS WIDGET */
.popular_contents_list {
  margin-top: 20px;

  h6 {
    font-size: 12px;
  }

  small {
    font-size: 11px;
  }

  .media-body {
    padding-left: 10px;
    word-break: break-all;
  }
}

.popular_contents_list small .fa:first-of-type {
  margin-left: 0px;
}

.popular_contents_list small .fa {
  color: lightgray;
  margin-right: 1px;
  margin-left: 8px;
}

/* USER PROFILE */
.user_info_widget h4 {
  margin-top: 0;
}

.user_info_widget p {
  margin: 0;
  font-size: 13px;
  word-wrap: break-word;
}

.user_info_widget .desc {
  font-style: italic;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid rgba(100, 100, 100, 0.16);
}

.toggle_night_mode {
  cursor: pointer;
}

.toggle_night_mode .glyphicon {
  font-size: 12px;
  color: #979797;
}

.group_list {
  -moz-column-count: 1;
  -webkit-column-count: 1;
  column-count: 1;
  float: left;
  width: 100%;
}

@media (min-width: 992px) {
  .group_list {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

.group_list .card {
  display: inline-block;
  width: 100%;
}

.group_list .media-object {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.group_list .info {
  display: block;
}

.group_list h3 {
  font-size: 1.3rem;
  margin-bottom: 0;
  small {
    font-size: 0.8rem;
  }
}

.comment_parent {
  background: #F5F2F2;
  padding: 5px;
}

.comment_parent span {
  color: #999;
}

/* FOLDERS */
.folder-menu li {
  padding: 0 5px;
}

.folder-menu label {
  font-weight: normal;
}

/* UTILS */
.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.hide {
  display: none;
}

.btn-group-xs>.btn, .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.panel-heading {
  border-bottom: 1px solid #E4E4E4;
  background-color: #f5f5f5;
}

.form-horizontal .form-group {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  margin-right: -15px;
  margin-left: -15px;
}

.form-horizontal .control-label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: right;
}


