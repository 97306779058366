.twitter-typeahead {
  width: 100%;
  position: relative;
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
  width: 100%;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.twitter-typeahead .tt-hint {
  color: #a1a1a1;
  padding: 6px 12px;
  border: 1px solid transparent;
  font-size: 14px;
}

.twitter-typeahead .tt-query {
  z-index: 2;
  border-radius: 4px !important;
}

.input-group-addon + .twitter-typeahead > .tt-query {
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.input-group-appended > .twitter-typeahead > .tt-query {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.tt-dropdown-menu {
  min-width: 250px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .2);
*border-right-width: 2px;
*border-bottom-width: 2px;
-webkit-border-radius: 6px;
-moz-border-radius: 6px;
border-radius: 6px;
-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
-webkit-background-clip: padding-box;
-moz-background-clip: padding;
background-clip: padding-box;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
  cursor: pointer;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
}

.tt-suggestion:hover a {
  color: #fff;
}

.tt-suggestion p {
  margin: 0;
}

.tt-suggestion img.avatar {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.tt-suggestion .count {
  color: #B6B6B6;
  font-size: 90%;
  vertical-align: top;
  margin-left: 8px;
}
